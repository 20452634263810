import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { TipoDeReceptora } from "../interfaces/tipo-de-receptora.interface";
import { Injectable } from "@angular/core";

@Injectable()
export class TipoDeReceptoraService {
    private baseUrl: string = environment.API.BASE_URL;

    constructor(private httpClient: HttpClient) {}
  
    /**
     * @return Lista de tipos de receptora
     */
    getAll(irisTenantId: string): Observable<TipoDeReceptora[]> {
      const url = `${this.baseUrl}/api/companies`.replace(/[?&]$/, '');
  
      return this.httpClient.get<TipoDeReceptora[]>(`${url}/${irisTenantId}/tipos-receptora`);
    }
}