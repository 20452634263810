import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import notify from 'devextreme/ui/notify';
import { ILogInfo } from 'src/app/interfaces/log-info.interface';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { LogInfo } from 'src/app/models/log-info';
import { ReceiverSettings } from 'src/app/models/receiver-settings';
import { ReceiverService } from 'src/app/services/receiver.service';

@Component({
  selector: 'app-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss'],
})
export class LoggingComponent implements OnInit, OnDestroy {
  @ViewChild(DxScrollViewComponent, { static: false })
  scrollView!: DxScrollViewComponent;
  currentReceiver: IReceiverSettings;
  popupVisible: boolean;
  interval: any;
  seedingMilliseconds: number;
  logList: ILogInfo[];

  constructor(private receiverService: ReceiverService) {
    this.currentReceiver = new ReceiverSettings();
    this.popupVisible = false;
    this.seedingMilliseconds = 5 * 1000;
    this.logList = Array<LogInfo>(
      new LogInfo(
        undefined,
        'Aqui serão listadas as 500 comunições mais recentes transmitidas para este receiver:',
        '#fffff'
      )
    );
  }

  ngOnInit(): void {}

  changeVisible(e: any): boolean {
    return this.popupVisible;
  }

  startLogging(receiver: IReceiverSettings): void {
    this.currentReceiver = receiver;
    this.popupVisible = true;

    if (this.interval) {
      this.stopGettingLog();
    }

    this.startGettingLog();
  }

  stopLogging(): void {
    this.popupVisible = false;

    this.stopGettingLog();
  }

  startGettingLog(): void {
    this.interval = setInterval(async () => {
      if (this.popupVisible) {
        this.getLog();
      } else {
        this.stopLogging();
      }
    }, this.seedingMilliseconds);
  }

  stopGettingLog(): void {
    clearInterval(this.interval);
  }

  async getLog(): Promise<void> {
    return this.receiverService
      .getLog(
        this.currentReceiver.companyId,
        this.currentReceiver.id,
        this.logList.map((log) => log.occurrenceDate)[this.logList.length - 1]
      )
      .then((response) => {
        response = response?.map((log) => {
          if (log) {
            log.description = log?.description?.replace('	', '&emsp;');
          }

          return log;
        });

        const missing = response?.filter(
          (log) =>
            log &&
            this.logList
              .map((log2) => log2.description)
              .indexOf(log.description) < 0
        );

        missing?.forEach((item) => {
          if (item) {
            this.logList.push(item);
          }
        });

        if (missing?.length > 0) {
          setTimeout(
            () =>
              this.scrollView.instance.scrollBy(
                this.scrollView.instance.scrollHeight()
              ),
            500
          );
        }
      })
      .catch((e) => {});
  }

  ngOnDestroy(): void {
    this.stopLogging();
  }
}
