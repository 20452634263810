export enum ReceiverProtocol {
  Elite = 'Receiver.Elite.Receiver',
  Intelbras = 'Receiver.Intelbras.Receiver',
  JFL = 'Receiver.JFL.Receiver',
  Serial = 'Receiver.Serial.Receiver',
  Vetti = 'Receiver.Vetti.Receiver',
  Radioenge = 'Receiver.Radioenge.Receiver',
  HikVision = 'Receiver.HikVision.Receiver',
  Linear = 'Receiver.Linear.Receiver',
  Zeus = 'Receiver.Zeus.Receiver',
  PPA = 'Receiver.PPA.Receiver',
  ViaWebCloud = 'Receiver.ViaWeb.Cloud.Receiver',
  IntelbrasIncendio = 'Receiver.Intelbras.Incendio.Receiver'
}
