import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ICompany } from 'src/app/interfaces/company.interface';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { ReceiverSettings } from 'src/app/models/receiver-settings';
import { ReceiverService } from 'src/app/services/receiver.service';

@Component({
  selector: 'app-receiver-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class ReceiverCreateComponent implements OnInit {
  receiver: IReceiverSettings;
  popupVisible: boolean;

  @Input() company!: ICompany;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onCreated: EventEmitter<any> = new EventEmitter();

  constructor(private receiverService: ReceiverService) {
    this.receiver = new ReceiverSettings();
    this.popupVisible = false;
  }

  ngOnInit(): void { }

  showDialog(): void {
    this.popupVisible = true;
  }

  createReceiver(): void {
    this.receiverService
      .create(this.receiver)
      .then((response) => {
        notify('Receiver criado com sucesso', 'success');
        this.popupVisible = false;
        this.onCreated.emit();
        this.receiver = new ReceiverSettings();
      })
      .catch((e) => { });
  }
}
