import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { custom } from 'devextreme/ui/dialog';
import { IPanelStatusList } from 'src/app/interfaces/panel-status.interface';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { PanelService } from 'src/app/services/panel.service';
import { ReceiverService } from 'src/app/services/receiver.service';
import { ConnectionComponent } from '../../connections/connection.component';
import { EventComponent } from '../../events/event.component';
import { PanelComponent } from '../../panels/panel.component';
import { ReceiverDetailsComponent } from '../details/detail.component';
import { ReceiverUpdateComponent } from '../update/update.component';
import { LoggingComponent } from '../logging/logging.component';
import notify from 'devextreme/ui/notify';
import { DeleteDialog } from 'src/app/shared/components/delete-confirmation-dialog/delete-dialog';
import { isAdmin } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-receiver-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class ReceiverCardComponent implements OnInit, OnDestroy {
  @ViewChild(PanelComponent) panelComponent!: PanelComponent;
  @ViewChild(EventComponent) eventComponent!: EventComponent;
  @ViewChild(ConnectionComponent) connectionComponent!: ConnectionComponent;
  @ViewChild(ReceiverDetailsComponent)
  detailsComponent!: ReceiverDetailsComponent;
  @ViewChild(ReceiverUpdateComponent) editComponent!: ReceiverUpdateComponent;
  @ViewChild(LoggingComponent) loggingComponent!: LoggingComponent;

  @Input() receiver!: IReceiverSettings;
  @Input() showCompanyName!: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onDeleted: EventEmitter<any> = new EventEmitter();

  isAdmin = isAdmin();

  clicked: boolean;
  seedingMilliseconds: number;
  seeingEvents: boolean;
  seeingPanels: boolean;
  seeingConnections: boolean;
  popupVisible: boolean;
  interval: any;

  constructor(
    private receiverService: ReceiverService,
    private panelService: PanelService
  ) {
    this.clicked = false;
    this.popupVisible = false;
    this.interval = {};
    this.seedingMilliseconds = 60 * 1000;
    this.seeingEvents = false;
    this.seeingPanels = false;
    this.seeingConnections = false;
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {
    if (this.receiver.running) {
      this.startGettingPanelsStatus();
    }
  }

  start(): void {
    this.clicked = true;

    this.receiverService
      .start(this.receiver.companyId, this.receiver.id)
      .then((response) => {
        this.changeRunningFlag(true);
        this.startGettingPanelsStatus();
      })
      .catch((e) => {})
      .finally(() => {
        this.clicked = false;
      });
  }

  stop(): void {
    const dialog = custom({
      title: 'Parando Receiver',
      messageHtml: `Tem certeza que deseja parar o receiver: <strong>${this.receiver.name?.toUpperCase()}</strong>?<br><br><small>Isso pode trazer graves consequências.</br>Caso não saiba o que está fazendo entre em contato com nosso suporte!<br><a href="https://sistemairis.com.br/contato/" target="_blank">Fale conosco</a></small>`,
      buttons: [
        {
          text: 'Cancelar',
          stylingMode: 'contained',
          type: 'success',
          onClick: (e) => {
            return false;
          },
        },
        {
          text: 'Parar',
          stylingMode: 'contained',
          type: 'danger',
          onClick: (e) => {
            return true;
          },
        },
      ],
    });

    dialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.clicked = true;

        this.receiverService
          .stop(this.receiver.companyId, this.receiver.id)
          .then((response) => {
            this.changeRunningFlag(false);

            this.stopGettingEvents();
            this.stopGettingPanels();
            this.stopGettingConnections();

            this.removeInterval();
          })
          .catch((e) => {})
          .finally(() => {
            this.clicked = false;
          });
      }
    });
  }

  startLogging(): void {
    this.loggingComponent?.startLogging(this.receiver);
  }

  edit(): void {
    this.editComponent?.showDialog(this.receiver);
  }

  details(): void {
    this.detailsComponent?.showDialog(this.receiver);
  }

  getPanelsStatus(): void {
    this.panelService
      .getStatusList(this.receiver.companyId, this.receiver.id)
      .then((panelStatusList: IPanelStatusList) => {
        this.receiver.panelStatusList = panelStatusList;
      });
  }

  startGettingPanelsStatus(): void {
    this.getPanelsStatus();

    this.interval = setInterval(() => {
      this.getPanelsStatus();
    }, this.seedingMilliseconds);
  }

  removeInterval(): void {
    clearInterval(this.interval);
  }

  private changeRunningFlag(running: boolean): void {
    this.receiver.running = running;
  }

  getEvents(): void {
    if (!this.seeingEvents) {
      this.seeingEvents = true;

      this.eventComponent?.startGettingEvents()?.then(() => {
        this.stopGettingPanels();

        this.stopGettingConnections();
      });
    } else {
      this.stopGettingEvents();
    }
  }

  stopGettingEvents(): void {
    this.seeingEvents = false;

    this.eventComponent?.stopGettingEvents();
  }

  getPanels(): void {
    if (!this.seeingPanels) {
      this.seeingPanels = true;

      this.panelComponent?.startGettingPanels()?.then(() => {
        this.stopGettingEvents();

        this.stopGettingConnections();
      });
    } else {
      this.stopGettingPanels();
    }
  }

  stopGettingPanels(): void {
    this.seeingPanels = false;

    this.panelComponent?.stopGettingPanels();
  }

  getConnections(): void {
    if (!this.seeingConnections) {
      this.seeingConnections = true;

      this.connectionComponent?.startGettingConnections()?.then(() => {
        this.stopGettingEvents();
        this.stopGettingPanels();
      });
    } else {
      this.stopGettingConnections();
    }
  }

  stopGettingConnections(): void {
    this.seeingConnections = false;

    this.connectionComponent?.stopGettingConnections();
  }

  delete(): void {
    const dialog = new DeleteDialog(
      'Excluindo Receiver',
      `Tem certeza que deseja excluir o receiver: <strong>${this.receiver.name?.toUpperCase()}</strong>?`
    );

    dialog.show().then((dialogResult: boolean) => {
      if (dialogResult) {
        this.receiverService
          .delete(this.receiver.companyId, this.receiver.id)
          .then((response) => {
            notify('Receiver excluido com sucesso', 'success');
            this.onDeleted.emit(this.receiver.id);
          });
      }
    });
  }
}
