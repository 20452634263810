<dx-form id="user-form" [formData]="company" [colCount]="1">
  <dxi-item dataField="code" required [disabled]="!editable">
    <dxo-label text="Código"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="Código é obrigatório."
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="name" required [disabled]="!editable">
    <dxo-label text="Nome"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="Nome é obrigatório."
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item dataField="connectionString" required [disabled]="!editable">
    <dxo-label text="String de Conexão"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="String de Conexão é obrigatório."
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
    editorType="dxTextArea"
    dataField="description"
    required
    [disabled]="!editable"
  >
    <dxo-label text="Descrição"></dxo-label>
    <dxi-validation-rule
      type="required"
      message="Descrição é obrigatório."
    ></dxi-validation-rule>
  </dxi-item>
  <dxi-item
  editorType="dxTextArea"
  dataField="irisTenantId"
  required
  [disabled]="!editable"
>
  <dxo-label text="Tenant Id Iris"></dxo-label>
  <dxi-validation-rule
    type="required"
    message="Tenant Iris Id é obrigatório."
  ></dxi-validation-rule>
</dxi-item>
</dx-form>
