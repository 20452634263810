import { IWebReceiverSettings } from "../interfaces/web-receiver-settings.interface";

export class WebReceiverSettings implements IWebReceiverSettings{
    id = 0;
    webReceiverIp?: string | undefined;
    webReceiverToken?: string | undefined;
    webReceiverClientName?: string | undefined;
    webReceiverUsername?: string | undefined;
    webReceiverPassword?: string | undefined;
    webReceiverClientType?: string | undefined;
    webReceiverVideoFolder?: string | undefined;
    maxDevicesListed?: number | undefined;
    webReceiverInitializationVector?: number | undefined;
}
