import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import SerialTransmissionType from 'src/app/enums/serial-transmission-type';
import { SerialProtocol } from 'src/app/enums/serial-protocol';
import { WorkDataMethod } from 'src/app/enums/work-data-method';
import { ICompany } from 'src/app/interfaces/company.interface';
import { IReceiverSettings } from 'src/app/interfaces/receiver-settings.interface';
import { ReceiverSettings } from 'src/app/models/receiver-settings';
import { SerialSettings } from 'src/app/models/serial-settings';
import { KeyValuePair } from 'src/app/utils/key-value-pair';
import { StopBits } from 'src/app/enums/stop-bits';
import { Parity } from 'src/app/enums/parity';
import { Handshake } from 'src/app/enums/handshake';
import { ReceiverProtocol } from 'src/app/enums/receiver-protocol';
import { WebReceiverSettings } from 'src/app/models/web-receiver-settings';
import { TipoDeReceptoraService } from 'src/app/services/tipo-de-receptora.service';
import { TipoDeReceptora } from 'src/app/interfaces/tipo-de-receptora.interface';

@Component({
  selector: 'app-receiver-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ReceiverFormComponent implements OnInit, OnChanges {
  @Input() receiver!: IReceiverSettings;
  @Input() company!: ICompany;
  @Input() editable!: boolean;
  @Input() creating!: boolean;

  tiposDeReceptora: TipoDeReceptora[];

  constructor(
    private tipoDeReceptoraService: TipoDeReceptoraService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.receiver = new ReceiverSettings();
    this.tiposDeReceptora = new Array<TipoDeReceptora>();
  }

  ngOnInit(): void {
    if (this.creating) {
      this.editable = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.company?.currentValue) {
      this.company = changes?.company?.currentValue;
      this.receiver.companyId = this.company.id;
      this.getTiposDeReceptora(this.company.irisTenantId);
      return;
    }

    if (changes?.receiver?.currentValue) {
      this.company = changes?.receiver?.currentValue.company;
      this.receiver.companyId = this.company.id;
      this.getTiposDeReceptora(this.company.irisTenantId);
    }
  }

  getTiposDeReceptora(irisTenantId: string) {
    this.tipoDeReceptoraService.getAll(irisTenantId).subscribe({
      next: response => this.tiposDeReceptora = response,
      error: err => console.log(err),
      complete: () => this.changeDetector.detectChanges()
    });
  }

  onIsSerialChange(event: any): void {
    this.receiver.type = event;

    switch (this.receiver.type) {
      case ReceiverProtocol.Serial:
        this.receiver.serialSettings = new SerialSettings();
        this.setSerialDefaults(this.receiver.serialSettings.serialProtocol);
        this.receiver.webReceiverSettings = undefined;
        break;
      case ReceiverProtocol.Radioenge:
      case ReceiverProtocol.HikVision:
      case ReceiverProtocol.ViaWebCloud:
      case ReceiverProtocol.IntelbrasIncendio:
        this.receiver.webReceiverSettings = new WebReceiverSettings();
        this.receiver.serialSettings = undefined;
        break;
      default:
        this.receiver.serialSettings = undefined;
        this.receiver.webReceiverSettings = undefined;
        break;
    }
  }

  public get ReceiverProtocol(): typeof ReceiverProtocol {
    return ReceiverProtocol;
  }

  getReceiverProtocols(): KeyValuePair[] {
    return KeyValuePair.build(ReceiverProtocol);
  }
  
  getWorkDataMethods(): KeyValuePair[] {
    return KeyValuePair.build(WorkDataMethod);
  }

  getReceiverSerialProtocols(): KeyValuePair[] {
    return KeyValuePair.build(SerialProtocol);
  }

  getReceiverSerialTransmissionTypes(): KeyValuePair[] {
    return KeyValuePair.build(SerialTransmissionType);
  }

  getReceiverSerialStopBits(): KeyValuePair[] {
    return KeyValuePair.build(StopBits);
  }

  getReceiverSerialParity(): KeyValuePair[] {
    return KeyValuePair.build(Parity);
  }

  getReceiverSerialHandshake(): KeyValuePair[] {
    return KeyValuePair.build(Handshake);
  }

  setSerialDefaults(event: any): void {
    if (this.receiver.serialSettings) {
      this.receiver.serialSettings.serialProtocol = event;

      switch (this.receiver.serialSettings.serialProtocol) {
        case SerialProtocol.Ademco685:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.Bosch:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.CM1000:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 9600;
          break;
        case SerialProtocol.MCDI:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 9600;
          break;
        case SerialProtocol.MCDIExprecium:
          this.receiver.serialSettings.messageTerminator = 13;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.Surgard:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 1200;
          break;
        case SerialProtocol.SurgardSystem:
          this.receiver.serialSettings.messageTerminator = 20;
          this.receiver.serialSettings.baudRate = 9600;
          break;
      }
    }
  }

  updatingIntegrationsIds(value: any): void {
    if (!value || value.endsWith(',')) {
      return;
    }

    this.receiver.integrationsIDs = value
      .split(',')
      ?.map((item: any) => item?.trim().replace(/\D/g, ''))
      .filter((item: any) => item && +item);
  }
}
