import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import 'devextreme/data/odata/store';
import { EventService } from 'src/app/services/event.service';
import { IEvent } from 'src/app/interfaces/event.interface';

@Component({
  selector: 'app-events',
  templateUrl: 'event.component.html',
})
export class EventComponent implements OnInit, OnDestroy {
  @Input() companyId!: number;
  @Input() receiverId!: number;
  visible: boolean;
  events: IEvent[];
  interval: any;
  seedingMilliseconds: number;

  constructor(private eventService: EventService) {
    this.events = [];
    this.interval = {};
    this.visible = false;
    this.seedingMilliseconds = 60 * 1000;
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {}

  stopGettingEvents(): void {
    this.visible = false;

    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  async startGettingEvents(): Promise<void> {
    this.visible = true;

    await this.getEvents();

    this.interval = setInterval(() => {
      this.getEvents();
    }, this.seedingMilliseconds);
  }

  async getEvents(): Promise<void> {
    await this.eventService
      .getEvents(this.companyId, this.receiverId)
      .then((events: IEvent[]) => {
        this.events = events;
      });
  }
}
