import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {
  SideNavOuterToolbarModule,
  SideNavInnerToolbarModule,
  SingleCardModule,
} from './layouts';
import { FooterModule } from './shared/components';
import { ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { ReceiverService } from './services/receiver.service';
import { CompanyService } from './services/company.service';
import { PanelService } from './services/panel.service';
import { EventService } from './services/event.service';
import { ConnectionService } from './services/connection.service';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './pages/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { RequestInterceptor } from './interceptors/request.interceptor';
import { ResponseInterceptor } from './interceptors/response.interpceptor';
import { UserService } from './services/user.service';
import { AdminGuard } from './guards/admin-guard';
import { TipoDeReceptoraService } from './services/tipo-de-receptora.service';

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    CommonModule,
    BrowserModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    // ResetPasswordFormModule,
    // CreateAccountFormModule,
    // ChangePasswordFormModule,
    // LoginFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    // AuthService,
    ScreenService,
    AppInfoService,

    // MINE
    ReceiverService,
    CompanyService,
    PanelService,
    EventService,
    UserService,
    ConnectionService,
    TipoDeReceptoraService,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
