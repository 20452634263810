<form name="receiver-form" class="form-horizontal">
  <div class="form-group row">

    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Instância</label>
      <input type="text" class="form-control" maxlength="5" placeholder="Instância"
        [class.is-invalid]="!receiver.instanceID" [(ngModel)]="receiver.instanceID" name="instanceID" required
        [readonly]="!editable" />
    </div>
    <div class="col-md-4">
      <label class="col-form-label" for="text-input">Nome</label>
      <input type="text" class="form-control" placeholder="Nome" [class.is-invalid]="!receiver.name"
        [(ngModel)]="receiver.name" name="name" required [readonly]="!editable" />
    </div>
    <div class="col-md-6">
      <label class="col-form-label" for="text-input">Descrição</label>
      <input type="text" class="form-control" placeholder="Descrição" [class.is-invalid]="!receiver.description"
        [(ngModel)]="receiver.description" name="description" required [readonly]="!editable" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Identificador do cliente</label>
      <input type="text" class="form-control" maxlength="1" placeholder="Idenficador do cliente"
        [class.is-invalid]="receiver.clientPrefix === undefined" [(ngModel)]="receiver.clientPrefix" name="clientPrefix"
        required [readonly]="!editable" />
    </div>
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Porta</label>
      <input type="number" min="0" step="1" class="form-control" placeholder="Porta"
        [class.is-invalid]="!receiver.receiverPort && receiver.type != ReceiverProtocol.IntelbrasIncendio && receiver.type != ReceiverProtocol.ViaWebCloud"
        [(ngModel)]="receiver.receiverPort" name="receiverPort" required [readonly]="!editable" />
    </div>
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Porta de serviço</label>
      <input type="number" min="0" step="1" class="form-control" placeholder="Porta de serviço"
        [class.is-invalid]="!receiver.servicePort && ( receiver.type == ReceiverProtocol.ViaWebCloud )"
        [(ngModel)]="receiver.servicePort" name="servicePort" required [readonly]="!editable" />
    </div>
    <div class="col-md-3">
      <label class="col-form-label" for="text-input">Tipo do receiver</label>
      <select class="browser-default custom-select" [(ngModel)]="receiver.receiverType" name="receiverType" required
        [disabled]="!editable">
        <option *ngFor="let item of tiposDeReceptora" [ngValue]="item.idTipo">
          {{ item.descricao }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <label class="col-form-label" for="text-input">IDs de integração</label>
      <input type="text" class="form-control" placeholder="IDs de integração" [class.is-invalid]="
        (!receiver.integrationsIDs || receiver.integrationsIDs.length === 0) && receiver.type != ReceiverProtocol.Serial
      " [ngModel]="receiver.integrationsIDs" (ngModelChange)="updatingIntegrationsIds($event)" name="integrationsIDs"
        required [readonly]="!editable" />
    </div>
  </div>

  <div class="form-group row">
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Nome do SQLite</label>
      <input type="text" class="form-control" placeholder="Nome do SQLite" [class.is-invalid]="!receiver.bufferName"
        [(ngModel)]="receiver.bufferName" name="bufferName" required [readonly]="!editable" />
    </div>
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Eventos por segundo</label>
      <input type="number" min="0" step="1" class="form-control" placeholder="Eventos por segundo"
        [class.is-invalid]="!receiver.eventsPerSecond" [(ngModel)]="receiver.eventsPerSecond" name="eventsPerSecond"
        required [readonly]="!editable" />
    </div>
    <div class="col-md-2">
      <label class="col-form-label" for="text-input">Salvar dados via</label>
      <select class="browser-default custom-select" [(ngModel)]="receiver.workDataMethod" name="workDataMethod" required
        [disabled]="!editable">
        <option *ngFor="let item of getWorkDataMethods()" [ngValue]="item.key">
          {{ item.value }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <label class="col-form-label" for="text-input">Protocolo</label>
      <select class="browser-default custom-select" [class.is-invalid]="!receiver.type" [ngModel]="receiver.type"
        (ngModelChange)="onIsSerialChange($event)" name="type" required [disabled]="!editable">
        <option *ngFor="let item of getReceiverProtocols()" [ngValue]="item.value">
          {{ item.key }}
        </option>
      </select>
    </div>
    <div class="col-md-3">
      <label class="col-form-label" for="text-input">Receiver backup</label>
      <select class="browser-default custom-select" [(ngModel)]="receiver.backupReceiver" name="backupReceiver" required
        [disabled]="!editable">
        <option [ngValue]="true">Sim</option>
        <option [ngValue]="false">Não</option>
      </select>
    </div>
  </div>

  <div *ngIf="receiver.type == ReceiverProtocol.ViaWebCloud && receiver.webReceiverSettings">
    <hr />
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Chave AES</label>
        <input type="text" class="form-control" placeholder="Chave AES"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverToken"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverToken" name="webReceiverToken" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Vetor de inicialização AES</label>
        <input type="text" class="form-control" placeholder="Vetor de inicialização AES"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverInitializationVector"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverInitializationVector"
          name="webReceiverInitializationVector" required [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <div class="form-group row">
          <label class="col-form-label" for="text-input">IP Web Receiver</label>
          <input type="text" class="form-control" placeholder="Ip WebReceiver"
            [class.is-invalid]="!receiver.webReceiverSettings.webReceiverIp"
            [(ngModel)]="receiver.webReceiverSettings.webReceiverIp" name="webReceiverIp" required
            [readonly]="!editable" />
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="receiver.type == ReceiverProtocol.IntelbrasIncendio && receiver.webReceiverSettings">
    <hr />
    <div class="form-group row">
      <div class="col-md-12">
        <label class=" col-form-label" for="text-input">API Key</label>
        <input type="text" class="form-control" placeholder="API Key do painel"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverToken"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverToken" name="webReceiverToken" required
          [readonly]="!editable" />
      </div>
    </div>
  </div>

  <div *ngIf="receiver.webReceiverSettings != null && receiver.type == ReceiverProtocol.Radioenge">
    <hr />
    <div class="form-group row">
      <div class="col-md-6">
        <label class="col-form-label" for="text-input">IP WebReceiver</label>
        <input type="text" class="form-control" placeholder="IP WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverIp"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverIp" name="webReceiverIp" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-6">
        <label class="col-form-label" for="text-input">Token WebReceiver</label>
        <input type="text" class="form-control" placeholder="Token WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverToken"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverToken" name="webReceiverToken" required
          [readonly]="!editable" />
      </div>
    </div>
  </div>

  <div *ngIf="receiver.webReceiverSettings != null && receiver.type == ReceiverProtocol.HikVision">
    <hr />
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">IP WebReceiver</label>
        <input type="text" class="form-control" placeholder="IP WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverIp"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverIp" name="webReceiverIp" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Username WebReceiver</label>
        <input type="text" class="form-control" placeholder="Username WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverUsername"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverUsername" name="webReceiverUsername" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Senha WebReceiver</label>
        <input type="text" class="form-control" placeholder="Senha WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverPassword"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverPassword" name="webReceiverPassword" required
          [readonly]="!editable" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Client Name WebReceiver</label>
        <input type="text" class="form-control" placeholder="Client Name WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverClientName"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverClientName" name="webReceiverClientName" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Client Type WebReceiver</label>
        <input type="text" class="form-control" placeholder="Client Type WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverClientType"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverClientType" name="webReceiverClientType" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-4">
        <label class="col-form-label" for="text-input">Video Folder WebReceiver</label>
        <input type="text" class="form-control" placeholder="Video Folder WebReceiver"
          [class.is-invalid]="!receiver.webReceiverSettings.webReceiverVideoFolder"
          [(ngModel)]="receiver.webReceiverSettings.webReceiverVideoFolder" name="webReceiverVideoFolder" required
          [readonly]="!editable" />
      </div>
    </div>
  </div>

  <div *ngIf="receiver.workDataMethod == 1" class="form-group row">
    <div class="col-md-6">
      <label class="col-form-label" for="text-input">URL da API</label>
      <input type="text" class="form-control" placeholder="URL da API" [class.is-invalid]="!receiver.apiUrl"
        [(ngModel)]="receiver.apiUrl" name="apiUrl" required [readonly]="!editable" />
    </div>
    <div class="col-md-6">
      <label class="col-form-label" for="text-input">Token da API</label>
      <input type="text" class="form-control" placeholder="Token da API" [class.is-invalid]="!receiver.apiToken"
        [(ngModel)]="receiver.apiToken" name="apiToken" required [readonly]="!editable" />
    </div>
  </div>

  <div *ngIf="receiver.serialSettings != null">
    <hr />
    <div class="form-group row">
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Protocolo Serial</label>
        <select class="browser-default custom-select" [ngModel]="receiver.serialSettings.serialProtocol"
          (ngModelChange)="setSerialDefaults($event)" name="serialProtocol" required [disabled]="!editable">
          <option *ngFor="let item of getReceiverSerialProtocols()" [ngValue]="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Método de recebimento</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.serialTransmissionType"
          name="serialTransmissionType" required [disabled]="!editable">
          <option *ngFor="let item of getReceiverSerialTransmissionTypes()" [ngValue]="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Modo</label>
        <input type="text" class="form-control" placeholder="Modo"
          [class.is-invalid]="!receiver.serialSettings.trasmissionMode"
          [(ngModel)]="receiver.serialSettings.trasmissionMode" name="trasmissionMode" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">ACK</label>
        <input type="number" class="form-control" placeholder="Receiver ACK"
          [class.is-invalid]="!receiver.serialSettings.ack" [(ngModel)]="receiver.serialSettings.ack" name="ack"
          required [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Terminador</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Terminador"
          [class.is-invalid]="!receiver.serialSettings.messageTerminator"
          [(ngModel)]="receiver.serialSettings.messageTerminator" name="messageTerminator" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Gera LOG</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.logging" name="logging"
          required [disabled]="!editable">
          <option [ngValue]="true">Sim</option>
          <option [ngValue]="false">Não</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Segundos para enviar ACK</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Segundos para enviar ACK"
          [class.is-invalid]="!receiver.serialSettings.secondsToSendAck"
          [(ngModel)]="receiver.serialSettings.secondsToSendAck" name="secondsToSendAck" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Segundos para receber o ACK</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Segundos para ter o ACK"
          [class.is-invalid]="!receiver.serialSettings.secondsToHaveHack"
          [(ngModel)]="receiver.serialSettings.secondsToHaveHack" name="secondsToHaveHack" required
          [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Milliseconds para leitura</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Milliseconds para leitura"
          [class.is-invalid]="!receiver.serialSettings.mSecondsToRead"
          [(ngModel)]="receiver.serialSettings.mSecondsToRead" name="mSecondsToRead" required [readonly]="!editable" />
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Stop Bits</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.stopBits" name="stopBits"
          required [disabled]="!editable">
          <option *ngFor="let item of getReceiverSerialStopBits()" [ngValue]="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Paridade</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.parity" name="parity"
          required [disabled]="!editable">
          <option *ngFor="let item of getReceiverSerialParity()" [ngValue]="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
      <div class="col-md-2">
        <label class="col-form-label" for="text-input">Handshake</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.handshake" name="handshake"
          required [disabled]="!editable">
          <option *ngFor="let item of getReceiverSerialHandshake()" [ngValue]="item.key">
            {{ item.value }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-3">
        <label class="col-form-label" for="text-input">Porta COM</label>
        <input type="text" class="form-control" placeholder="Porta COM"
          [class.is-invalid]="!receiver.serialSettings.comPort" [(ngModel)]="receiver.serialSettings.comPort"
          name="comPort" required [readonly]="!editable" />
      </div>
      <div class="col-md-3">
        <label class="col-form-label" for="text-input">Velocidade</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Velocidade"
          [class.is-invalid]="!receiver.serialSettings.baudRate" [(ngModel)]="receiver.serialSettings.baudRate"
          name="baudRate" required [readonly]="!editable" />
      </div>
      <div class="col-md-3">
        <label class="col-form-label" for="text-input">Data Bits</label>
        <input type="number" min="0" step="1" class="form-control" placeholder="Data Bits"
          [class.is-invalid]="!receiver.serialSettings.dataBits === undefined"
          [(ngModel)]="receiver.serialSettings.dataBits" name="dataBits" required [readonly]="!editable" />
      </div>
      <div class="col-md-3">
        <label class="col-form-label" for="text-input">Tem DTR?</label>
        <select class="browser-default custom-select" [(ngModel)]="receiver.serialSettings.hasDtr" name="hasDtr"
          required [disabled]="!editable">
          <option [ngValue]="true">Sim</option>
          <option [ngValue]="false">Não</option>
        </select>
      </div>
    </div>
  </div>
</form>