import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ICompany } from 'src/app/interfaces/company.interface';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-company-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss'],
})
export class CompanyUpdateComponent implements OnInit {
  company: ICompany;
  popupVisible: boolean;
  editing: boolean;

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onUpdated: EventEmitter<any> = new EventEmitter();

  constructor(private companyService: CompanyService) {
    this.company = new Company();
    this.popupVisible = false;
    this.editing = false;
  }

  ngOnInit(): void {}

  showDialog(company: Company): void {
    this.company = company;
    this.popupVisible = true;
  }

  updateCompany(): void {
    this.companyService
      .update(this.company)
      .then((response) => {
        notify('Empresa editada com sucesso', 'success');
        this.popupVisible = false;
        this.onUpdated.emit();
      })
      .catch((e) => {});
  }
}
