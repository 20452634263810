<div class="page-title-content">
  <div class="content-block">
    <h2 class="page-title">Receivers</h2>
    <span *ngIf="company?.name" innerHtml="&nbsp - {{ company?.name }}"></span>
  </div>
  <div class="total-counts">
    <span title="Total de painéis Online">Online:
      <strong class="online">{{
        totalPanelStatusList.onlineCount
        }}</strong></span>
    <span title="Total de painéis Offline">Offline:
      <strong class="offline">{{
        totalPanelStatusList.offlineCount
        }}</strong></span>
    <span title="Total de painéis em Falha de Keep">Falha de Keep:
      <strong class="keep-fail">{{
        totalPanelStatusList.keepFailCount
        }}</strong></span>
    <span title="Total de painéis Desconhecidos">Desconhecidos:
      <strong class="unknow">{{
        totalPanelStatusList.unknownCount
        }}</strong></span>
  </div>

  <div class="search-content" p>
    <div b></div>
    <div c>
      <div class="search dark" p>
        <div b></div>
        <div c>
          <i class="dx-icon-search"></i>
          <input type="text" placeholder="Buscar receiver..." [ngModel]="searchText"
            (ngModelChange)="onSearch($event)" />
        </div>
      </div>
    </div>
  </div>
</div>

<hr />

<div class="card-list">
  <app-receiver-card *ngFor="let receiver of filteredReceivers" [receiver]="receiver" (onDeleted)="onDeleted($event)"
    [showCompanyName]="company?.name === undefined"></app-receiver-card>
</div>

<!-- Modal para create -->
<app-receiver-create *ngIf="isAdmin" (onCreated)="onCreated($event)" [company]="company!"></app-receiver-create>

<dx-speed-dial-action *ngIf="isAdmin" icon="add" title="Adicionar novo receiver" hint="Adicionar novo receiver"
  [index]="1" (onClick)="create()">
</dx-speed-dial-action>