import { IReceiverSettings } from '../interfaces/receiver-settings.interface';
import { SerialSettings } from './serial-settings';
import { WorkDataMethod } from '../enums/work-data-method';
import { IPanelStatusList } from '../interfaces/panel-status.interface';
import { Company } from './company';
import { ReceiverProtocol } from '../enums/receiver-protocol';
import { WebReceiverSettings } from './web-receiver-settings';

export class ReceiverSettings implements IReceiverSettings {
  id = 0;
  companyId = 0;
  serialId = 0;
  instanceID = '';
  name?: string | undefined;
  description?: string | undefined;
  receiverIP = '0.0.0.0';
  receiverPort?: number;
  servicePort = 0;
  servicePassword: string | undefined;
  clientPrefix = '1';
  integrationsIDs: number[] = Array<number>();
  receiverType: string | undefined;
  eventsPerSecond = 100;
  bufferName?: string | undefined;
  backupReceiver = false;
  type = ReceiverProtocol.Intelbras;
  workDataMethod = WorkDataMethod.SQLServer;
  apiUrl?: string | undefined;
  apiToken?: string | undefined;
  running = false;
  panelStatusList?: IPanelStatusList;
  serialSettings?: SerialSettings;
  webReceiverSettings?: WebReceiverSettings;
  company?: Company;
  transmissionModeDefault: string | undefined;
  maxSilence: number | undefined;
}
